.leaderboard {
  flex: 1; /* Занимаем оставшееся пространство */
  background-color: #1e1e1e;
  padding: 20px;
}

.leaderboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leaderboard-header h2 {
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
  margin-top: 50px;
}

.leaderboard-content {
  margin-top: 20px;
}

.leaderboard-row {
  display: flex;
  background-color: #3d3d3d;
  margin-bottom: 12px;
  border-radius: 5px;
  overflow: hidden;
}

.leaderboard-row.header {
  background-color: transparent;
  margin-bottom: 0;
}

.leaderboard-cell {
  flex: 1;
  padding: 15px;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
  overflow: hidden; /* Скрываем переполнение */
  text-overflow: ellipsis; /* Добавляем многоточие */
  white-space: nowrap; /* Запрещаем перенос строк */
}

.leaderboard-row.header .leaderboard-cell {
  font-weight: bold;
  color: #ffffff;
}

.leaderboard-row .leaderboard-cell:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.leaderboard-row .leaderboard-cell:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

h2 {
  margin: 0;
}

/* Стили для фильтра */
.filters {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.filters select,
.filters input {
  padding: 10px;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
  background-color: #2d2d2d;
  border: 1px solid #3d3d3d;
  border-radius: 5px;
}

.filters select:focus,
.filters input:focus {
  outline: none;
  border-color: #5d5d5d;
}

.filters option {
  color: #ffffff;
  background-color: #2d2d2d;
}

/* Адаптивные стили */
@media (max-width: 768px) {
  .leaderboard-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .leaderboard-row.header {
    display: none; /* Скрываем заголовок на маленьких экранах */
  }

  .leaderboard-cell {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #2d2d2d;
    white-space: normal; /* Разрешаем перенос строк */
    text-overflow: clip; /* Убираем многоточие */
  }

  .leaderboard-cell::before {
    content: attr(data-label);
    font-weight: bold;
  }

  .leaderboard-cell:last-child {
    border-bottom: none;
  }

  .leaderboard-row .leaderboard-cell:first-child {
    border-radius: 0;
  }

  .leaderboard-row .leaderboard-cell:last-child {
    border-radius: 0;
  }

  /* Стили для фильтра на маленьких экранах */
  .filters {
    flex-direction: column;
    gap: 10px;
  }

  .filters select,
  .filters input {
    width: 100%;
  }
}
