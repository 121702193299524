.user-card {
  background-color: #3d3d3d;
  border-radius: 5px;
  max-width: 425px;
  padding: 20px;
  width: 100%;

  position: sticky;
  top: 100px;
}

.user-card h2 {
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
  margin-bottom: 10px;
}

.user-card p {
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
  margin: 5px 0;
}

.user-card .avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.user-card .stats {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
}

.user-card .stat-item {
  flex: 1 1 calc(50% - 10px); /* Два элемента в строке с отступами */
  background-color: #2d2d2d;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

@media (max-width: 600px) {
  .user-card {
    position: static; /* Убираем прилипание на маленьких экранах */
    margin: 10px;
  }

  .user-card .stats {
    flex-direction: column;
  }

  .user-card .stat-item {
    flex: 1 1 100%;
  }
}
