/* Общие стили */
.main-content {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.content-container {
    display: flex;
    align-items: stretch;
    max-width: 1920px;
    width: 100%;
}

/* Сайдбар */
.sidebar {
    background-color: #1e1e1e;
    width: 550px;
}

/* Лидерборд */
.leaderboard {
    flex: 1;
    background-color: #1e1e1e;
    padding: 20px;
}

/* Адаптивные стили */
@media (max-width: 768px) {
    .content-container {
        flex-direction: column;
    }

    .sidebar {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .leaderboard {
        width: 100%;
    }
}
