/* Глобальный сброс box-sizing */
* {
  box-sizing: border-box;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2d2d2d;
  padding: 0 20px;
  height: 60px;
  position: sticky;
  top: 0;
}

.navbar-logo {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.navbar-logo h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  margin-left: 10px;
}

.logo {
  width: 30px;
  height: 30px;
}

.navbar-buttons {
  display: flex;
  align-items: center;
}

.button-style,
.button-style:visited {
  margin-left: 10px;
  margin-right: 10px;
  background-color: #3d3d3d;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.button-style:hover {
  background-color: #5d5d5d;
}

a {
  color: inherit;
  text-decoration: none;
}

/* Адаптивные стили */
@media (max-width: 600px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    height: auto;
  }

  .navbar-logo {
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
  }

  .navbar-logo h1 {
    font-size: 14px;
  }

  .navbar-buttons {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }

  .button-style {
    margin: 5px 0;
    width: 100%;
    box-sizing: border-box;
  }
}
