.marketplace {
  background-color: #3d3d3d; /* Серый фон для карточки */
  border-radius: 10px;
  width: 425px;
  height: 185px;

  margin-top: 32px;
  margin-right: 32px;
  margin-left: 32px
}

.marketplace .title h2{
  margin: 36px 247px 120px 26px;

  font-size: 24px; /* Размер шрифта */
  font-weight: 500;
  font-family: 'Montserrat', sans-serif; /* Применяем шрифт Montserrat */

  position: absolute;
}

.marketplace .coming-soon p{
  margin: 144px 25px 40px 297px;

  font-size: 14px; /* Размер шрифта */
  font-family: 'Montserrat', sans-serif; /* Применяем шрифт Montserrat */


  position: absolute;

}



.stamp {

  margin: 25px 25px 25px 230px; /* Отступы: сверху, справа и снизу 25px */
  position: absolute;
  
}