/* Styling for the login link */
.login-container {
  text-align: center;
  margin-top: 20px;
}

.login-link {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.login-link:hover {
  background-color: #0056b3;
}
