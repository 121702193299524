.sidebar {
  background-color: #1e1e1e;
  display: flex;            /* Make the sidebar a flex container */
  flex-direction: column;   /* Arrange children vertically */
  align-items: center;      /* Center children horizontally */
}



@media (max-width: 992px) {
  .sidebar{
    width: 100%;
  }
}

