.App {
  background-color: #1e1e1e; /* Тёмный фон */
  color: #ffffff; /* Белый текст */
  min-height: 100vh;
}

.main-content {
  display: flex;
  justify-content: center;
}


@media  (max-width: 992px) {
  .main-content{
    flex-direction: column;
  }
}
